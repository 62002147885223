import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  CircularProgress,
  Notistack,
  TextFieldPhone,
  TitleTextField,
  Typography,
} from 'vlab-frontend-components'
import { cpfMask, phoneMask } from '../../utils/masks'
import {
  Background,
  LoginBox,
  CheckboxContainer,
  CheckboxLabel,
  Link,
  Logo,
  StyledButton,
} from './styles'
import { useMutation } from 'react-query'
import { login } from '../../hooks/useAPi'
import { sanitize } from '../../utils/sanitize'
import { getS3BackgroundUrl, getS3LogoUrl, getS3SettingsUrl, getConfig } from '../../utils/s3'
import { atom, useAtom } from 'jotai'
import { useMixpanel } from '../../hooks/useMixpanel'

export const clientConfigAtom = atom({
  patient_portal_logo: '',
  patient_portal_background: '',
  patient_portal_settings: {
    country: 'br',
    loginButton: {
      backgroundColor: '',
      borderColor: '',
      textColor: '',
    },
    pageStyle: {
      header: '',
      page: '#0166FF',
      headerTitleColor: '',
    },
    menu: {
      organizationName: '',
      menuItemBackground: '',
      menuItemTextColor: '',
    },
  },
})

export default function Login() {
  const navigate = useNavigate()
  const { enqueueSnackbar } = Notistack.useSnackbar()
  const [acceptTerms, setAcceptTerms] = useState(false)
  const [acceptConsent, setAcceptConsent] = useState(false)
  const [loading, setLoading] = useState(true)
  const [clientConfig, setClientConfig] = useAtom(clientConfigAtom)
  const jwt = localStorage.getItem('jwt')

  const patient_data = () => {
    const stored_data = localStorage.getItem('patient_data')
    if (stored_data) {
      return JSON.parse(stored_data)
    }
    return null
  }
  const { identify, trackEvent } = useMixpanel();

  const [dataForm, setDataForm] = useState({
    cpf: '',
    cellphone: '',
    pin: '',
  })

  useEffect(() => {
    redirect()
    getClientConfig()
  }, [])

  async function redirect() {
    const defaultUrl = process.env.REACT_APP_ENV === 'production' ? 'https://patient.vlab.live/' : 'https://stg-patient-portal.vlab.live/';
    const path = window.location.hash.slice(1).replaceAll('/', '');

    if (path === '') {
      return;
    }

    try {
      const response = await getConfig();
      const data = await response.json();

      if (data[path]) {
        window.location.href = `https://${data[path]}/`;
      } else {
        window.location.href = defaultUrl;
      }
    } catch (error) {
      console.error('Failed to fetch config:', error);
      window.location.href = defaultUrl;
    }
  }


  async function getClientConfig() {
    setLoading(true)
    await getS3LogoUrl().then((url) => {
      setClientConfig((prev) => ({
        ...prev,
        patient_portal_logo: url,
      }))
    })
    await getS3BackgroundUrl().then((url) => {
      setClientConfig((prev) => ({
        ...prev,
        patient_portal_background: url,
      }))
    })

    await getS3SettingsUrl()
      .then(async (url) => {
        const settingsJson = await url.json()

        setClientConfig((prev) => ({
          ...prev,
          patient_portal_settings: settingsJson,
        }))
      })
      .catch((error) => {
        console.log(error)
      })
    setLoading(false)
  }

  useEffect(() => {
    if (jwt && patient_data) {
      identify(patient_data['cellphone'], patient_data['cpf'], patient_data['pin'])
      navigate('/medical-reports')
    }
  }, [jwt, navigate])

  const loginMutation = useMutation({
    mutationFn: login,
    onSuccess: (data) => {
      localStorage.setItem('jwt', data)
      localStorage.setItem('patient_data', JSON.stringify(dataForm))

      identify(dataForm.cellphone, dataForm.cpf, dataForm.pin)
      trackEvent("WEB_PATIENT_LOGIN", {})

      navigate('/medical-reports')
    },
    onError: () => {
      enqueueSnackbar({
        message: 'Tivemos um erro ao tentar fazer o login,verifique seus dados e tente novamente',
        variant: 'error',
      })
    },
  })

  function handleLogin() {
    if (dataForm.cpf.length < 11 || dataForm.cellphone.length < 13 || !dataForm.pin) {
      enqueueSnackbar({
        message: 'Preencha todos os campos corretamente',
        variant: 'error',
      })
      return
    }

    if (!acceptTerms || !acceptConsent) {
      enqueueSnackbar({
        message: 'Aceite os termos e condições para continuar',
        variant: 'error',
      })
      return
    }

    loginMutation.mutate({
      cpf: sanitize(dataForm.cpf),
      cellphone: dataForm.cellphone,
      pin: dataForm.pin,
    })
  }

  return (
    <Background backgroundUrl={clientConfig?.patient_portal_background}>
      {loading ? (
        <CircularProgress style={{ width: '50px', height: '50px' }} />
      ) : (
        <LoginBox>
          {clientConfig.patient_portal_logo && (
            <Logo
              src={clientConfig.patient_portal_logo}
              onError={(e) => {
                ; (e.target as HTMLImageElement).onerror = null
                  ; (e.target as HTMLImageElement).src =
                    'https://stg-patient-portal-assets.vlab.live/stg-patient-portal_vlab_live/logo.png'
              }}
              style={{ marginBottom: '16px' }}
            />
          )}

          <TitleTextField
            maxLength={14}
            title="CPF"
            variant="outlined"
            style={{ width: '100%' }}
            value={cpfMask(dataForm.cpf)}
            onChange={(event) =>
              setDataForm((prev) => ({
                ...prev,
                cpf: event.target.value,
              }))
            }
          />
          <div
            style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'start' }}
          >
            <Typography type="poppinsBodyS" color="greyDark" style={{ marginBottom: '6px' }}>
              {' '}
              Celular
            </Typography>
            <TextFieldPhone
              value={phoneMask(dataForm.cellphone)}
              country={clientConfig?.patient_portal_settings?.country.toLowerCase() || 'br'}
              onChange={(event) =>
                setDataForm((prev) => ({
                  ...prev,
                  cellphone: event,
                }))
              }
            />
          </div>
          <TitleTextField
            title="Código de acesso"
            variant="outlined"
            style={{ width: '100%' }}
            value={dataForm.pin}
            onChange={(event) =>
              setDataForm((prev) => ({
                ...prev,
                pin: event.target.value,
              }))
            }
          />

          <div>
            <CheckboxContainer>
              <input
                type="checkbox"
                id="terms"
                onChange={(event) => setAcceptTerms(event.target.checked)}
              />
              <CheckboxLabel htmlFor="terms">
                Li os{' '}
                <Link
                  href="https://documentos.vlabhealth.com/TERMOS_DE_USO_08_2023.pdf"
                  target="_blank"
                  rel="noreferrer"
                  style={{
                    color: clientConfig.patient_portal_settings.pageStyle.page,
                  }}
                >
                  Termos de Uso
                </Link>{' '}
                e{' '}
                <Link
                  href="https://documentos.vlab.live/POLITICA_DE_PRIVACIDADE_08_2023.pdf"
                  target="_blank"
                  rel="noreferrer"
                  style={{
                    color: clientConfig.patient_portal_settings.pageStyle.page,
                  }}
                >
                  Política de Privacidade.
                </Link>
              </CheckboxLabel>
            </CheckboxContainer>

            <CheckboxContainer>
              <input
                type="checkbox"
                id="consent"
                onChange={(event) => {
                  setAcceptConsent(event.target.checked)
                }}
              />
              <CheckboxLabel htmlFor="consent">
                Li, concordo e dou meu consentimento para o{' '}
                <Link
                  href="https://documentos.vlabhealth.com/TERMO_DE_COLETA_DE_DADOS_DE_SAUDE_08_2023.pdf"
                  target="_blank"
                  rel="noreferrer"
                  style={{
                    color: clientConfig.patient_portal_settings.pageStyle.page,
                  }}
                >
                  Termo de Coleta de Dados de Saúde.
                </Link>
              </CheckboxLabel>
            </CheckboxContainer>
            <StyledButton
              backgroundColor={clientConfig?.patient_portal_settings?.loginButton.backgroundColor}
              borderColor={clientConfig?.patient_portal_settings?.loginButton.borderColor}
              textColor={clientConfig?.patient_portal_settings?.loginButton.textColor}
              onClick={() => handleLogin()}
              variant="primary"
              style={{ width: '100%', padding: '16px', marginTop: '16px' }}
            >
              Entrar
            </StyledButton>
          </div>
        </LoginBox>
      )}
    </Background>
  )
}
