import { RxExit } from 'react-icons/rx'
import { useNavigate } from 'react-router-dom'
import {
  Button,
  ButtonIconClose,
  ExamIcon,
  MenuFooter,
  MenuHeader,
  MenuHeaderItem,
  MenuItem,
  MenuList,
  ReportIcon,
  Sidebar,
  Typography,
} from 'vlab-frontend-components'
import FlexContainer from '../Flex'
import { clientConfigAtom } from '../../pages/Login'
import { useAtom } from 'jotai'
import styled from 'styled-components'
import { useMixpanel } from '../../hooks/useMixpanel'

const LogoImage = styled.img`
  width: 40px;
  height: 40px;
  object-fit: contain;
`

const StyledSidebar = styled(Sidebar)`
  @media screen and (max-width: 880px) {
    display: block;
  }
`

export default function SidebarComponent({
  isSidebarOpen,
  setIsSidebarOpen,
}: {
  isSidebarOpen: boolean
  setIsSidebarOpen: (value: boolean) => void
}) {
  const [clientConfig] = useAtom(clientConfigAtom)
  const { reset } = useMixpanel()
  const navigate = useNavigate()
  const isMobile = window.innerWidth < 1000

  return (
    <StyledSidebar
      variant={'secondary'}
      open={isSidebarOpen}
      position="left"
      onMouseEnter={() => setIsSidebarOpen(true)}
      onMouseLeave={() => setIsSidebarOpen(false)}
      onClick={() => isMobile && setIsSidebarOpen(!isSidebarOpen)}
      fullScreenOnMobile
    >
      <MenuHeader
        open={isSidebarOpen}
        backgroundColor={
          clientConfig.patient_portal_settings.pageStyle.page &&
          clientConfig.patient_portal_settings.pageStyle.page
        }
      >
        <MenuHeaderItem
          avatar={
            <LogoImage
              src={clientConfig.patient_portal_logo}
              onError={(e) => {
                ;(e.target as HTMLImageElement).onerror = null
                ;(e.target as HTMLImageElement).src =
                  'https://stg-patient-portal-assets.vlab.live/stg-patient-portal_vlab_live/white_logo.png'
              }}
            />
          }
          label={
            <Typography color="white100" type="subheadingS">
              {clientConfig?.patient_portal_settings?.menu?.organizationName}
            </Typography>
          }
          settings={
            window.innerWidth < 620 ? (
              <ButtonIconClose
                onClick={() => setIsSidebarOpen(false)}
                variant="primary"
                iconColor="#F7F8FC"
              />
            ) : null
          }
        />
      </MenuHeader>
      <MenuList onClick={() => {}}>
        <div style={{ height: '100vh' }}>
          <div
            onClick={(e) => {
              e.stopPropagation()
              if (isMobile) {
                setIsSidebarOpen(false)
              }
              navigate('/medical-reports')
            }}
          >
            <MenuItem
              open={isSidebarOpen}
              icon={<ReportIcon />}
              label={
                <Typography color="greyDark" type="subheadingS">
                  Resultados
                </Typography>
              }
              adminColor={'#51525F'}
              backgroundColor={'#D0D0D0'}
              onClick={() => {}}
              // adminColor={
              //   clientConfig.patient_portal_settings.menu.menuItemTextColor &&
              //   clientConfig.patient_portal_settings.menu.menuItemTextColor
              // }
              // backgroundColor={
              //   clientConfig.patient_portal_settings.menu.menuItemBackground &&
              //   clientConfig.patient_portal_settings.menu.menuItemBackground
              // }
            />
          </div>

          <div
            onClick={(e) => {
              e.stopPropagation()
              if (isMobile) {
                setIsSidebarOpen(false)
              }
              navigate('/exams')
            }}
          >
            <MenuItem
              open={isSidebarOpen}
              icon={<ExamIcon />}
              adminColor={'#51525F'}
              backgroundColor={'#D0D0D0'}
              label={
                <Typography color="greyDark" type="subheadingS">
                  {' '}
                  Ultrassons
                </Typography>
              }
              onClick={() => {}}
              // adminColor={clientConfig.patient_portal_settings.menu.menuItemTextColor && clientConfig.patient_portal_settings.menu.menuItemTextColor}
              // backgroundColor={clientConfig.patient_portal_settings.menu.menuItemBackground && clientConfig.patient_portal_settings.menu.menuItemBackground}
            />
          </div>
        </div>
      </MenuList>
      <MenuFooter open={isSidebarOpen}>
        <Button
          style={{
            margin: '8px',
            width: isSidebarOpen ? '95%' : 'auto',
            backgroundColor:
              clientConfig.patient_portal_settings.loginButton.backgroundColor &&
              clientConfig.patient_portal_settings.loginButton.backgroundColor,
          }}
          variant="primary"
        >
          <FlexContainer
            alignItems="center"
            justifyContent="center"
            cursor="pointer"
            onClick={() => {
              reset()
              localStorage.clear()
              navigate('/')
            }}
          >
            <RxExit
              size="18px"
              style={{ marginRight: '8px' }}
              color={clientConfig.patient_portal_settings.loginButton.textColor}
            />
            {isSidebarOpen && (
              <Typography color="white100" type="subheadingS">
                Sair
              </Typography>
            )}
          </FlexContainer>
        </Button>
      </MenuFooter>
    </StyledSidebar>
  )
}
